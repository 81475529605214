<template>
  <div>
    <img :src="img" style="width: 100%" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/image/extra.png"),
    };
  },
};
</script>
